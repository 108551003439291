<template>
    <Navbar :user="user" />
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="instructor-details-desc">
                <div class="list-group text-start">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
                        <router-link :to="{ name: 'TrainerTestQuestionsAdd' }" class="btn default-btn">Add A Question</router-link>
                    </div>
                    <div v-if="questions.length">
                        <h3 class="text-center">All Questions</h3>
                        <ul class="list-group" v-for="question in questions" :key="question.id">
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                <div class="fw-bold">{{ question.title }} 
                                    <span v-if="question.test_question_options.length" class="text-default">
                                        ({{ question.test_question_options.length }}) <span v-if="question.test_question_options.length === 1"> option</span><span v-else>options</span>
                                    </span>
                                    <span v-else class="text-default">
                                        No Question Added
                                    </span>
                                </div>
                                </div>
                                <span class="badge text-default rounded-pill">
                                    <router-link :to="{ name: 'TrainerTestQuestionsDetails', params: {id: question.id}}" class="btn text-default">
                                       <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                    </router-link>
                                    <button class="btn text-default" @click="deleteQuestion(question.id)">
                                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>  
                                    </button>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="text-center" v-else>
                        <h3>No Question Has Been Added</h3>
                    </div>
                </div>
                <div class="text-center pt-5">
                    <router-link class="btn default-btn" :to="{ name: 'CourseDashboard', params: {id: test.course_id}}">Back To Course</router-link>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
export default {
  components: { Navbar,Loading  },
    data() {
        return {
            isLoading: true,
            questions: {},
            test:{},
            token: localStorage.getItem('user_data'),
            user: {},
            errors: {}
        }
    },
    methods:{
        deleteQuestion(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/test_question/delete/'+ id)
                .then(response => {
                    response.data
                        this.questions = this.questions.filter(question => {
                        this.$emit("refreshCourse")
                        return question.id != id
                    })
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Question has been Deleted.',
                'success'
                )
            }
            })
        },
        getQuestions(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/test_question/get_test_question_with_options/' + id).then(response => {
                this.questions = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getTest(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/test/profile/' + id).then(response => {
                this.test = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                this.$router.push({ name: 'Login'})
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
    },
    created(){
        document.title = `Test Question - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getTest()
        this.getQuestions()
    }
}
</script>

<style>

</style>